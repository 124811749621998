// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ErrorComponent/NoAccessPage.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ErrorComponent/NoAccessPage.tsx");
  import.meta.hot.lastModified = "1726719770000";
}
// REMIX HMR END

import { AnchorLink } from '~/components/AnchorLink';
import { APP_PAGES_URL } from '~/lib/constants';
import { formatUrlParams } from '~/lib/utils';
import { useParams } from '@remix-run/react';
export const NoAccessPage = ({
  title = 'No Access',
  message = 'Hi, You do not have access to this page. You can request the admin for access or go back to home page',
  homeUrl = APP_PAGES_URL.APP
}) => {
  _s();
  const {
    workspaceId,
    entityId
  } = useParams();
  return <div>
      <div className='grid px-4 bg-white place-content-center min-h-[70dvh] max-w-full md:max-w-lg mx-auto'>
        <div className='text-center'>
          <div className='block p-4'>
            <img src='/assets/security.svg' alt='No access' className='max-w-full max-h-full block mx-auto w-full md:w-60 my-6' />
          </div>
          <h2 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            {title}
          </h2>
          <p className='my-4 text-gray-500'>{message}</p>
          <AnchorLink to={formatUrlParams(homeUrl, {
          workspaceId,
          entityId
        })} className='rounded-md bg-primary-400 hover:bg-primary-500 px-5 py-2.5 text-sm font-medium text-white shadow duration-150 transition-all'>
            Go Back to Homepage
          </AnchorLink>
        </div>
      </div>
    </div>;
};
_s(NoAccessPage, "QbA73giHnsKUy5c4rkVFJ0jmtLs=", false, function () {
  return [useParams];
});
_c = NoAccessPage;
var _c;
$RefreshReg$(_c, "NoAccessPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;